import React from 'react';
import Box from 'components/box/boxColumn';
import Layout from 'components/layout';
import SVG from 'react-svg';
import styles from './404.module.scss';

const NotFound = () => {
  const isDark = true;
  return (
    <Layout isDark={isDark}>
      <Box>
        <div className={styles.notfound}>
          <SVG className={styles.error} src={'/icons/404.svg'} />
          <p>
            Page you{'\''}re looking for is nowhere to be found. I{'\''}m sorry
            for that.
          </p>
          <p>
            Either hit back or click logo in top left corner to go to the
            homepage.
          </p>
        </div>
      </Box>
    </Layout>
  );
};

export default NotFound;
