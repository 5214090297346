import React from 'react';
import PropTypes from 'prop-types';
import styles from './box.module.scss';

const Box = ({ children, fullColumn }) => {
  const classes = [styles.column + ' '];
  if (fullColumn) classes.push(' ' + styles.fullColumn);
  return <div className={classes}>{children}</div>;
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
  fullColumn: PropTypes.bool,
};

export default Box;
